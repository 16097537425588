<template>
    <div id="page-customer">
        <base-layout>
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-back-to" :to="{ name: 'customers' }">Back to Customers</router-link>

                        <div class="header-info">
                            <h1 class="name">{{ fullname }}</h1>
                            <div class="status">Account status: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
                        </div>

                        <app-tabs v-model="tab" :tabs="tabs" :dropdown-on-tablet="true" />
                    </div>
                </div>

                <router-view></router-view>
            </div>
        </base-layout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'
import appTabs from '@/components/app-tabs'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,

        appLoader,
        appTabs,
    },

    data() {
        return {
            loading: false,
            loadedUUID: '',
        }
    },

    created() {
        this.init()
    },

    updated() {
        if (!this.loading && this.uuid && this.uuid != this.loadedUUID) {
            this.loading = true
            console.log('reloading', this.uuid, this.loadedUUID )
            this.init()
        }
    },

    methods: {
        init() {
            this.loadCustomerInfo()
        },

        loadCustomerInfo() {
            this.loading = true

            this.$store.dispatch('getCustomerByUUID', { uuid: this.uuid })
                .then(() => {
                    this.loadedUUID = this.uuid
                    this.loading = false
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.$router.push({ name: 'customers', replace: true })
                })
        },
    },

    computed: {
        ...mapGetters([
            'modules_env',

            'show_billing_tab',
            'show_orders_tab',

            'getCustomersByUUID',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        customer() {
            const customer = this.getCustomersByUUID(this.uuid)

            return customer ? customer : {}
        },

        fullname() {
            const fullname = [this.customer.FirstName, this.customer.LastName].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        status() {
            return this.loading ? {
                text: 'Loading',
                value: 'info',
            } : (
                this.customer.Active ? {
                    text: 'Active',
                    value: 'success',
                } : {
                    text: 'Inactive',
                    value: 'danger',
                }
            )
        },

        tabs() {
            return [
                {
                    id: 'customer-details',
                    name: 'customer-details',
                    title: 'Details',
                },

                ...(this.show_billing_tab ? [
                    {
                        id: 'customer-billing',
                        name: 'customer-billing',
                        title: 'Billing',
                    },
                ] : []),

                ...(this.show_orders_tab ? [
                    {
                        id: 'customer-orders',
                        name: 'customer-orders',
                        title: 'Orders',
                    },
                ] : []),

                {
                    id: 'customer-products',
                    name: 'customer-products',
                    title: 'Products',
                },
            ]
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: "customer-details", replace: true };
                }

                return tab
            },

            set(tab) {
                this.$router.push({
                    name: tab.name,
                    replace: true,
                })
            }
        },
    },
}
</script>

<style lang="scss">
#page-customer {
    margin-top: 24px;

    .app-tabs {
        margin-bottom: 34px;
    }

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 40px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-left: 32px;

            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-default);

            .label {
                margin-left: 16px;
                padding: 4px 12px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;

                &.success {
                    background-color: var(--color-success-bg);
                    color: var(--color-success);
                }

                &.info {
                    background-color: var(--color-info-bg);
                    color: var(--color-info);
                }

                &.warning {
                    background-color: var(--color-warning-bg);
                    color: var(--color-warning);
                }

                &.danger {
                    background-color: var(--color-danger-bg);
                    color: var(--color-danger);
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-customer {
        .header-info {
            width: 100%;
            margin-bottom: 48px;

            .name {
                font-size: 48px;
                line-height: 56px;
            }

            .status {
                font-size: 14px;
                line-height: 24px;
                margin-left: 16px;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-customer {
        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }

            .status {
                margin: 8px 0 0;
                justify-content: flex-start;

                .label {
                    margin-left: 12px;
                    line-height: 20px;
                }
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>